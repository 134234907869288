import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogCloseComponent } from '@components/ui/dialog-close/dialog-close.component';
import { ProgressBarComponent } from '@components/ui/progress-bar/progress-bar.component';
import { BalanceFormatPipe } from '@pipes/balance-format.pipe';
import { SortPipe } from '@pipes/sort.pipe';
import { UserStoreService } from '@services/user-store.service';

@Component({
  selector: 'ns-level-modal',
  standalone: true,
  imports: [
    DialogCloseComponent,
    ProgressBarComponent,
    BalanceFormatPipe,
    SortPipe,
  ],
  templateUrl: './level-modal.component.html',
  styleUrl: './level-modal.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelModalComponent {
  readonly balance = this.userStoreService.balance;
  readonly balanceForNextLevel = this.userStoreService.balanceForNextLevel;
  readonly precent = this.userStoreService.precent;

  readonly levelsInfo = this.userStoreService.levelsListInfo;
  readonly level = this.userStoreService.level;

  constructor(private userStoreService: UserStoreService) {}
}
