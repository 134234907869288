<ns-balance />
<div class="navigation-wrapper">
	<div class="navigation">
		@for (name of groupNames$ | async; track name.id) {
			<button
				(click)="activeId$.next(name.id)"
				nsVibrate
				[class.active]="activeId$.value === name.id"
			>
				{{ name.name }}
			</button>
		}
	</div>
</div>

@let group = selectedGroup$ | async;

<div class="description">{{ group?.description }}</div>
@if (activeId$.value === BoostTypeEnum.SECONDARY_SCHOOL) {
	<div class="improvers">
		<button (click)="openConfirmEnergyModal(energyReserve)" nsVibrate>
			<img src="assets/energy-reserve.svg" alt="" />
			<p>Запас энергии</p>
			<div class="tag">
				<img src="assets/pixel.svg" alt="" />
				500
			</div>
		</button>
		<button
			(click)="openConfirmMultitapModal(confirmMultitapRef)"
			nsVibrate
		>
			<img src="assets/multitap.svg" alt="" />
			<p>Мультитап</p>
			<div class="tag">
				<img src="assets/pixel.svg" alt="" />
				500
			</div>
		</button>

		<button
			(click)="openConfirmRebootModal(confirmRebootRef)"
			nsVibrate
			[disabled]="!isCanReboot()"
		>
			<img src="assets/reboot.svg" alt="" />
			<p>Перезагрузка</p>
			<div class="tag">
				@if (isCanReboot()) {
					Бесплатно
				} @else {
					@if (rebootTime$ | async; as time) {
						{{ time.hours }}: {{ time.minutes }}:{{ time.seconds }}
					}
				}
			</div>
		</button>
	</div>
}

<div class="boosts">
	<h2>Бусты</h2>
	<p class="boosts__description">
		После того, как ты закрыл игру, бусты<br />
		работают только 3 часа
	</p>
	@if (group?.isOpen || group?.isAvailable) {
		<div class="boosts__list">
			@for (boost of group?.boosts; track $index) {
				<button
					(click)="confirmPayment(confirmPaymentRef, boost)"
					nsVibrate
					class="boost"
				>
					@let nextLevel = boost.nextLevel;

					<img [src]="boost.icon" alt="" />
					<div class="boost__type">
						<h3>{{ boost.name }}</h3>
						<p>Ур. {{ nextLevel?.level }}</p>
					</div>
					<div class="boost__balance">
						<p>+{{ nextLevel?.incr }} в час</p>
						@if (nextLevel; as nextLevel) {
							<div>
								<img src="assets/pixel.svg" alt="" />
								{{ nextLevel.price | balanceFormat }}
							</div>
						}
					</div>

					<ng-template #confirmPaymentRef>
						<ns-confirm-modal>
							<button
								nsButton
								(click)="
									buyBoost(nextLevel?.id!, nextLevel?.incr!)
								"
							>
								Купить за
								{{ nextLevel?.price! | balanceFormat }}
								<img src="assets/pixel.svg" alt="" />
							</button>
						</ns-confirm-modal>
					</ng-template>
				</button>
			}
			<div class="empty"></div>
		</div>
	} @else {
		<ng-template #authPlug>
			<div class="auth-plug">
				<ns-icon icon="lock" size="48"></ns-icon>
				<h2>Недоступно без авторизации</h2>
				<p>
					Эти бусты доступны только тем, кто вошел<br />
					в аккаунт Новой Школы
				</p>
				<div>
					<button nsButton="blue" (click)="openLetMeetModal()">
						Войти в аккаунт Новой Школы
					</button>
				</div>
			</div>
		</ng-template>
		@if (group?.isRequiredAuthNs && !group?.isRequiredSubscriptionTg) {
			<ng-container *ngTemplateOutlet="authPlug"></ng-container>
		}
		@if (group?.isRequiredSubscriptionTg && !group?.isRequiredAuthNs) {
			<div class="tg-plug">
				<div class="wrapper-with-empty">
					<div></div>
					<div class="content-wrapper">
						<img src="assets/tg-icon.svg" alt="" />
						<h2>Подпишись на наш тг-канал</h2>
						<p>
							Присоединяйся к нам в телеграме и зарабатывай больше
							пикселей
						</p>
					</div>
				</div>

				<div class="action-wrapper">
					<a nsButton="blue" href="https://t.me/kruzhok_ns"
						>Подписаться</a
					>

					<button nsButton="inline" (click)="checkGroup()">
						Проверить
					</button>
				</div>
			</div>
		}
		@if (group?.isRequiredAuthNs && group?.isRequiredSubscriptionTg) {
			<ng-container *ngTemplateOutlet="authPlug"></ng-container>
		}
	}
</div>
<img src="assets/gradient.png" alt="" class="gradient" />
<ng-template #energyReserve>
	<ns-confirm-modal>
		<button nsButton (click)="recoveryEnergy()">
			<img src="assets/pixel.svg" alt="" />
			Купить за 500
		</button>
	</ns-confirm-modal>
</ng-template>
<ng-template #confirmMultitapRef>
	<ns-confirm-modal>
		<button nsButton (click)="recoveryEnergy()">
			<img src="assets/pixel.svg" alt="" />
			Купить за 500
		</button>
	</ns-confirm-modal>
</ng-template>

<ng-template #confirmRebootRef>
	<ns-confirm-modal>
		<button nsButton (click)="recoveryEnergy()">Бесплатно</button>
	</ns-confirm-modal>
</ng-template>
