import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VibrateDirective } from '@directives/vibrate.directive';

@Component({
	selector: 'ns-button, [nsButton]',
	standalone: true,
	imports: [],
	templateUrl: './button.component.html',
	styleUrl: './button.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'[class.primary]': '!theme || theme === "primary"',
		'[class.inline]': 'theme === "inline"',
		'[class.blue]': 'theme === "blue"',
		'[class.small]': 'theme === "small"',
		'[class.gray]': 'theme === "gray"',
	},
	hostDirectives: [VibrateDirective],
})
export class ButtonComponent {
	@Input('nsButton') theme:
		| 'primary'
		| 'inline'
		| 'blue'
		| 'gray'
		| 'small'
		| '' = 'primary';
}
