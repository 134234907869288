import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import innerHeight from 'ios-inner-height';
import { fromEvent, merge, of } from 'rxjs';

@NgModule({
	declarations: [],
	imports: [CommonModule],
})
export class WindowHeightModule {
	constructor() {
		merge(fromEvent(window, 'resize'), of(null)).subscribe(() => {
			console.log('er');

			window.document.documentElement.style.setProperty(
				'--window-height',
				`${innerHeight()}px`,
			);
		});
	}
}
