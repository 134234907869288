export const tasks = [
  {
    value: 'Выполни дз',
    reward: 1000,
  },
  {
    value: 'Посмотри урок',
    reward: 100,
  },
  {
    value: 'Выполни задачу',
    reward: 100,
  },
];
