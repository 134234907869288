import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IconComponent } from '@components/ui/icon/icon.component';
import { VibrateDirective } from '@directives/vibrate.directive';

@Component({
	selector: 'ns-dialog-close, [nsDialogClose]',
	standalone: true,
	imports: [IconComponent],
	templateUrl: './dialog-close.component.html',
	styleUrl: './dialog-close.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(click)': 'close()',
	},
	hostDirectives: [VibrateDirective],
})
export class DialogCloseComponent {
	constructor(private dialogRef: DialogRef) {}

	close(): void {
		this.dialogRef.close();
	}
}
