import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TelergamService } from '@services/telergam.service';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private telergamService: TelergamService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(request)).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          // this.authService.logout();
        }

        return throwError(err);
      })
    );
  }

  addToken(request: HttpRequest<any>): HttpRequest<any> {
    let tgId = localStorage.getItem('tgId');

    if (!tgId) {
      tgId = this.telergamService.tgData?.user?.id || '';
      tgId && localStorage.setItem('tgId', tgId);
    }

    // const headers = tgId
    //   ? request.headers.set('X-Tg-Id-Key', tgId)
    //   : request.headers;
    const headers = request.headers.set('X-Tg-Id-Key', '6618383337');
    return request.clone({
      headers,
    });
  }
}
