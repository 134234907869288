<h1>Пиксели за друзей</h1>
<p>
	Приглашай друзей в&nbsp;пиксиманию и&nbsp;получай пиксели за&nbsp;каждого
	друга
</p>
<div class="rewards">
	<div class="rewards__item">
		<div>
			<img src="assets/pixel.svg" alt="" />
			+50 000
		</div>
		<p>
			тебе<br />
			и другу
		</p>
	</div>
	<div class="rewards__item">
		<div>
			<img src="assets/pixel.svg" alt="" />
			+100 000
		</div>
		<p>
			если у друга<br />
			Telegram Premium
		</p>
	</div>
	<div class="rewards__item">
		<div>
			<img src="assets/pixel.svg" alt="" />
			+10 000 000
		</div>
		<p>
			если друг начнет<br />
			учиться в Новой Школе
		</p>
	</div>
</div>
<div class="friends">
	<div class="friends__header">
		<p>Твои друзья</p>
		<p>Получено</p>
	</div>
	@for (friend of friends(); track $index) {
		<div>
			<p>{{ friend.invitee.fName }}</p>
			<div class="balance">
				<img src="assets/pixel.svg" alt="" />
				{{ friend.reward | balanceFormat }}
			</div>
		</div>
	}
</div>
<div class="btn-group">
	<button nsButton="blue" (click)="inviteFriend()">Пригласить друга</button>
	<button nsButton="blue"><ns-icon icon="link" size="20" /></button>
</div>
