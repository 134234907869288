import { AutofillMonitor } from '@angular/cdk/text-field';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgControl,
  NgForm,
} from '@angular/forms';
import { FormFieldControl } from '@components/ui/forms/form-field/form-field.component';
import { ErrorStateMatcher } from '@services/error-state-matcher.service';
import { Subject } from 'rxjs';

export const INPUT_VALUE_ACCESSOR = new InjectionToken<{ value: any }>(
  'INPUT_VALUE_ACCESSOR'
);

@Directive({
  selector: '[nsInput]',
  providers: [{ provide: FormFieldControl, useExisting: InputDirective }],
  host: {
    '[attr.disabled]': 'disabled ? true : null',
  },
  standalone: true,
})
export class InputDirective implements AfterViewInit, OnDestroy {
  focused: boolean = false;
  readonly: boolean = false;
  autofilled: boolean = false;
  controlType = 'input';
  errorState: boolean = false;
  readonly stateChanges: Subject<void> = new Subject<void>();
  private inputValueAccessor: { value: any };
  @Input() disabled = false;

  constructor(
    private elementRef: ElementRef,
    private autofillMonitor: AutofillMonitor,
    private errorStateMatcher: ErrorStateMatcher,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() public parentForm: NgForm,
    @Optional() public parentFormGroup: FormGroupDirective,
    @Optional() @Self() @Inject(INPUT_VALUE_ACCESSOR) inputValueAccessor: any,
    private cdr: ChangeDetectorRef
  ) {
    const element = this.elementRef.nativeElement;
    this.inputValueAccessor = inputValueAccessor || element;
  }
  @Input()
  get value(): string {
    return this.inputValueAccessor.value;
  }
  set value(value: string) {
    if (value !== this.value) {
      this.inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }
  ngAfterViewInit(): void {
    // if (this._platform.isBrowser) {
    this.autofillMonitor
      .monitor(this.elementRef.nativeElement)
      .subscribe((event) => {
        this.autofilled = event.isAutofilled;
        this.stateChanges.next();
      });
    // }
  }

  ngOnChanges(): void {
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();

    // if (this._platform.isBrowser) {
    this.autofillMonitor.stopMonitoring(this.elementRef.nativeElement);
    // }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }
  }

  updateErrorState() {
    const oldState = this.errorState;
    const parent = this.parentFormGroup || this.parentForm;
    const matcher = this.errorStateMatcher;
    const control = this.ngControl
      ? (this.ngControl.control as FormControl)
      : null;
    const newState = matcher.isErrorState(control, parent);
    if (newState !== oldState) {
      this.errorState = newState;
      this.stateChanges.next();
      this.cdr.markForCheck();
    }
  }

  focus(options?: FocusOptions): void {
    this.elementRef.nativeElement.focus(options);
  }

  @HostListener('focus', ['true'])
  @HostListener('blur', ['false'])
  focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused && (!this.readonly || !isFocused)) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

  onContainerClick() {
    if (!this.focused) {
      this.focus();
    }
  }

  get empty(): boolean {
    return (
      !this.isNeverEmpty() &&
      !this.elementRef.nativeElement.value &&
      !this.isBadInput() &&
      !this.autofilled
    );
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  isNeverEmpty(): boolean {
    return false;
  }

  protected isBadInput() {
    // The `validity` property won't be present on platform-server.
    let validity = (this.elementRef.nativeElement as HTMLInputElement).validity;
    return validity && validity.badInput;
  }
}
