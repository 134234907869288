@for(i of 12 | range; track $index) {
<div
  [class]="{
    large: size === 'large',
    medium: size === 'medium',
    small: size === 'small'
  }"
></div>

}
