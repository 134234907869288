import { DialogModule } from '@angular/cdk/dialog';
import {
	HTTP_INTERCEPTORS,
	provideHttpClient,
	withFetch,
	withInterceptorsFromDi,
} from '@angular/common/http';
import {
	ApplicationConfig,
	LOCALE_ID,
	provideZoneChangeDetection,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { WindowHeightModule } from '@app/modiules/window-height.module';
import { AuthInterceptorService } from '@services/auth-interceptor.service';
import { UrlInterceptorService } from '@services/url-interceptor.service';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(routes),
		provideHttpClient(withInterceptorsFromDi(), withFetch()),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UrlInterceptorService,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true,
		},
		{ provide: LOCALE_ID, useValue: 'ru' },
		DialogModule,
		WindowHeightModule,
		provideAnimations(),
	],
};
