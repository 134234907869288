import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'ns-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class ErrorComponent {
}
