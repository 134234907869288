import { Routes } from '@angular/router';
import { ShellComponent } from '@components/ui/shell/shell.component';
import { BoostsComponent } from '@pages/boosts/boosts.component';
import { FriendsComponent } from '@pages/friends/friends.component';
import { LoginComponent } from '@pages/login/login.component';
import { MainComponent } from '@pages/main/main.component';
import { PrizesComponent } from '@pages/prizes/prizes.component';
import { TasksComponent } from '@pages/tasks/tasks.component';

export const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        component: MainComponent,
        data: { animation: 'main' },
      },
      {
        path: 'boosts',
        component: BoostsComponent,
        data: { animation: 'boosts' },
      },
      {
        path: 'tasks',
        component: TasksComponent,
        data: { animation: 'tasks' },
      },
      {
        path: 'friends',
        component: FriendsComponent,
        data: { animation: 'friends' },
      },
      {
        path: 'prizes',
        component: PrizesComponent,
        data: { animation: 'prizes' },
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { animation: 'login' },
  },
];
