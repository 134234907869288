import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BalanceComponent } from '@components/balance/balance.component';
import { LetMeetModalComponent } from '@components/ui/modals/let-meet-modal/let-meet-modal.component';
import { VibrateDirective } from '@directives/vibrate.directive';
import { PaymentPrizesModalComponent } from '@pages/prizes/components/payment-prizes-modal/payment-prizes-modal.component';
import { JackpotService } from '@services/jackpot.service';

@Component({
	selector: 'ns-prizes',
	standalone: true,
	imports: [BalanceComponent, VibrateDirective],
	templateUrl: './prizes.component.html',
	styleUrl: './prizes.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrizesComponent {
	constructor(
		private dialog: Dialog,
		private jackpotService: JackpotService,
	) {
		this.jackpotService.getTickets().subscribe();
	}

	openPaymentPrize(): void {
		this.dialog.open(PaymentPrizesModalComponent);
	}

	openLetMeetModal(): void {
		this.dialog.open(LetMeetModalComponent);
	}

	buyTicket(): void {
		// this.jackpotService.buyTicket().subscribe()
	}
}
