import { Dialog } from '@angular/cdk/dialog';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	OnInit,
	viewChild,
} from '@angular/core';
import { BalanceComponent } from '@components/balance/balance.component';
import { PlateComponent } from '@components/ui/layout/plate/plate.component';
import { LetMeetModalComponent } from '@components/ui/modals/let-meet-modal/let-meet-modal.component';
import { VibrateDirective } from '@directives/vibrate.directive';
import { tasks } from '@pages/main/main.constants';
import { CreatorTapPoolService } from '@services/creator-tap-pool.service';
import { PixieModelService } from '@services/pixie-model.service';
import { TelergamService } from '@services/telergam.service';
import { UserStoreService } from '@services/user-store.service';
import { UserService } from '@services/user.service';
import { take } from 'rxjs';

@Component({
	selector: 'ns-main',
	standalone: true,
	imports: [PlateComponent, BalanceComponent, VibrateDirective],
	templateUrl: './main.component.html',
	styleUrl: './main.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(pointerdown)': 'tap($event)',
		'(click)': ' this.pixieModelService.triggerMotion()',
	},
	providers: [CreatorTapPoolService],
})
export class MainComponent implements OnInit, AfterViewInit {
	isShowPlug = true;

	readonly tasks = tasks;
	readonly canvas = viewChild.required('live2dCanvas', {
		read: ElementRef<HTMLCanvasElement>,
	});

	constructor(
		private userService: UserService,

		private userStoreService: UserStoreService,

		private creatorTapPoolService: CreatorTapPoolService,
		private telergamService: TelergamService,
		private dialog: Dialog,
		private pixieModelService: PixieModelService,
	) {}

	async ngOnInit(): Promise<void> {
		this.pixieModelService.initModel(this.canvas());
	}

	ngAfterViewInit(): void {
		this.creatorTapPoolService.initializePool();
	}

	tap(event: PointerEvent): void {
		if (this.userStoreService.currentEnergy()) {
			this.userStoreService.balance.update(
				(value) => value + this.userStoreService.rewardPerClick(),
			);

			this.userStoreService.currentEnergy.update((value) => value - 1);
			this.userService.click().pipe(take(1)).subscribe();
			this.creatorTapPoolService.addTapElement(event);
			this.telergamService.vibrate();
		}
	}

	openLetMeetModal(): void {
		this.dialog.open(LetMeetModalComponent);
	}
}
