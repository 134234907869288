import { Injectable } from '@angular/core';
import { BoostGroupType } from '@models/boosts.model';
import { BoostService } from '@services/boost.service';
import { BehaviorSubject, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BoostStoreService {
  readonly boostGroups$ = new BehaviorSubject<BoostGroupType[] | null>(null);

  constructor(private boostService: BoostService) {}

  fetchBoostGroups(): void {
    this.boostService
      .getBoosts()
      .pipe(take(1))
      .subscribe((groups) => {
        this.boostGroups$.next(groups);
      });
  }
}
