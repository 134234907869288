import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BalanceFormatPipe } from '@pipes/balance-format.pipe';
import { NumberFormatPipe } from '@pipes/number-format.pipe';
import { RoundNumberPipe } from '@pipes/round-number.pipe';
import { UserStoreService } from '@services/user-store.service';

@Component({
	selector: 'ns-balance',
	standalone: true,
	imports: [RoundNumberPipe, BalanceFormatPipe, NumberFormatPipe],
	templateUrl: './balance.component.html',
	styleUrl: './balance.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceComponent {
	readonly balance = this.userStoreService.balance;
	readonly rewardPerHour = this.userStoreService.rewardPerHour;

	constructor(private userStoreService: UserStoreService) {}
}
