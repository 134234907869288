import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ns-icon',
  standalone: true,
  imports: [],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() icon!: string;
  @Input() width: number | string = 24;
  @Input() height: number | string = 24;

  @Input() set size(v: number | string) {
    this.width = this.height = v;
  }
}
