import { Directive } from '@angular/core';
import { TelergamService } from '@services/telergam.service';

@Directive({
  selector: '[nsVibrate]',
  standalone: true,
  host: {
    '(click)': 'this.telergamService.vibrate()',
  },
})
export class VibrateDirective {
  constructor(private telergamService: TelergamService) {}
}
