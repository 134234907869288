import { ElementRef, Injectable, Renderer2 } from '@angular/core';

@Injectable()
export class CreatorTapPoolService {
  private readonly elementPool: HTMLElement[] = [];
  private readonly maxPoolSize = 20; // Максимальное количество элементов в пуле
  private readonly activeElements: Set<HTMLElement> = new Set();

  constructor(private renderer: Renderer2, private host: ElementRef) {}

  public initializePool(): void {
    for (let i = 0; i < this.maxPoolSize; i++) {
      const element = this.renderer.createElement('div');
      this.renderer.setProperty(element, 'innerHTML', '+1');
      this.renderer.addClass(element, 'click-element');
      this.renderer.appendChild(this.host.nativeElement, element);
      this.elementPool.push(element);
    }
  }

  private getAvailableElement(): HTMLElement | null {
    return this.elementPool.find((el) => !this.activeElements.has(el)) || null;
  }

  private releaseElement(element: HTMLElement): void {
    this.renderer.removeClass(element, 'active');
    this.activeElements.delete(element);
  }

  public addTapElement(event: PointerEvent) {
    const element = this.getAvailableElement();
    if (!element) {
      console.warn('No available elements in pool.');
      return;
    }

    this.renderer.setStyle(element, 'left', `${event.clientX}px`);
    this.renderer.setStyle(element, 'top', `${event.clientY}px`);
    this.renderer.addClass(element, 'active');
    this.activeElements.add(element);
    setTimeout(() => this.releaseElement(element), 1000);
  }
}
