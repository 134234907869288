import { camelCase, snakeCase } from 'lodash';

export const iterate = (obj: any, fn: (key: string) => string): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => iterate(v, fn));
  } else if (obj !== null && obj !== undefined && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      return {
        ...result,
        [fn(key)]: iterate(obj[key], fn),
      };
    }, {});
  }
  return obj;
};

export const camelCaseKeys = (obj: any) => iterate(obj, camelCase);
export const snakeCaseKeys = (obj: any) => iterate(obj, snakeCase);
