import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, pairwise, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteHistoryService {
  readonly prevUrl$ = new Subject<string>();
  readonly currentUrl$ = new BehaviorSubject<string>('');
  readonly routeHistory$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    pairwise(),
    tap(([prev, current]) => {
      this.prevUrl$.next(prev.url.slice(1).split('?')[0]);
      this.currentUrl$.next(current.url.slice(1).split('?')[0]);
    })
  );

  constructor(private router: Router) {}
}
