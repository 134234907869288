import { Injectable, signal } from '@angular/core';
import { TaskType } from '@models/task.model';
import { TaskService } from '@services/task.service';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskStoreService {
  readonly tasks = signal<TaskType[]>([]);

  constructor(private taskService: TaskService) {}

  fetchTasks(): void {
    this.taskService
      .getTask()
      .pipe(take(1))
      .subscribe((tasks) => this.tasks.set(tasks));
  }
}
