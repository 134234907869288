import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BoostGroupType } from '@models/boosts.model';
import { ProgressType } from '@models/user.model';
import { camelCaseKeys } from '@utils/camel-case';
import { map, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class BoostService {
	constructor(private http: HttpClient) {}

	getBoosts(): Observable<BoostGroupType[]> {
		return this.http
			.get<BoostGroupType[]>('boost')
			.pipe(map(camelCaseKeys));
	}

	buyBoost(levelId: number): Observable<void> {
		return this.http.post<void>(`boost/buy/${levelId}`, {});
	}

	recoveryEnerge(): Observable<ProgressType> {
		return this.http
			.post<ProgressType>('boost/recovery_energy', {})
			.pipe(map(camelCaseKeys));
	}

	checkBoost(groupId: number): Observable<BoostGroupType> {
		return this.http.get<BoostGroupType>(`boost/${groupId}/check`);
	}
}
