import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash-es';

@Injectable({ providedIn: 'root' })
@Pipe({
  name: 'sort',
  standalone: true,
})
export class SortPipe implements PipeTransform {
  transform<T, K extends keyof T>(
    array: T[] | null | undefined,
    field: K,
    reverse = false
  ): T[] {
    if (!array) {
      return [];
    }

    const arr = [...array].sort((a, b) => {
      const aVal = get(a, field, '');
      const bVal: any = get(b, field, '');
      if (typeof aVal === 'string') {
        aVal.toLocaleLowerCase();
        return aVal.toLocaleLowerCase() < bVal.toLocaleLowerCase() ? -1 : 1;
      } else {
        return (aVal as number) < bVal ? -1 : 1;
      }
    });

    if (reverse) {
      arr.reverse();
    }

    return arr;
  }
}
