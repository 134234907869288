import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'balanceFormat',
  standalone: true,
})
export class BalanceFormatPipe implements PipeTransform {
  transform(value: number, toFixed = 0) {
    if (!value) {
      return 0;
    }

    if (value < 1000000) {
      return `${(value / 1000).toFixed(toFixed)}k`;
    }

    if (value < 1000000000) {
      return `${(value / 1000000).toFixed(toFixed)}M`;
    }
    if (value < 100000000000) {
      return `${(value / 1000000000).toFixed(toFixed)}B`;
    }
    return '';
  }
}
