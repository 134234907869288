import { ElementRef, Injectable } from '@angular/core';
import { PixieModelType } from '@models/pixie.model';

declare var window: any;

@Injectable({
	providedIn: 'root',
})
export class PixieModelService {
	model!: PixieModelType;

	constructor() {}

	async initModel(canvas: ElementRef): Promise<void> {
		const app = new window.PIXI.Application({
			view: canvas.nativeElement,
			transparent: true,
		});

		this.model = await window.PIXI.live2d.Live2DModel.from(
			'assets/pixi/pixi.model3.json',
		);
		this.model.scale.set(0.25);
		this.model.position.set(app.screen.width / 2, app.screen.height / 2);
		this.model.anchor.set(0.5);
		app.stage.addChild(this.model);
		//////////////////////////////////
		// const cubism4Model =
		//   'https://cdn.jsdelivr.net/gh/guansss/pixi-live2d-display/test/assets/haru/haru_greeter_t03.model3.json';
		// const model = await window.PIXI.live2d.Live2DModel.from(cubism4Model);
		// app.stage.addChild(model);
		// model.scale.set(0.25);
		// model.expression();
		//////////////////////////////////
		// const cubism2Model =
		//   'https://cdn.jsdelivr.net/gh/guansss/pixi-live2d-display/test/assets/shizuku/shizuku.model.json';
		// const model2 = await window.PIXI.live2d.Live2DModel.from(cubism2Model);
		// app.stage.addChild(model2);
		// model2.scale.set(0.25);
		// model2.position.set(app.screen.width / 2, app.screen.height / 2);
		// model2.anchor.set(0.5);
		// model2.expression();
	}

	triggerMotion(): void {
		this.model.motion('TapBody');
	}
}
