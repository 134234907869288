import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	TemplateRef,
} from '@angular/core';
import { WindowHeightModule } from '@app/modiules/window-height.module';
import { BalanceComponent } from '@components/balance/balance.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { IconComponent } from '@components/ui/icon/icon.component';
import { ConfirmModalComponent } from '@components/ui/modals/confirm-modal/confirm-modal.component';
import { LetMeetModalComponent } from '@components/ui/modals/let-meet-modal/let-meet-modal.component';
import { VibrateDirective } from '@directives/vibrate.directive';
import { BoostType } from '@models/boosts.model';
import { BalanceFormatPipe } from '@pipes/balance-format.pipe';
import { BoostStoreService } from '@services/boost-store.service';
import { BoostService } from '@services/boost.service';
import { UserStoreService } from '@services/user-store.service';
import { isNotNull } from '@utils/is-not-null';
import { DateTime } from 'luxon';
import {
	BehaviorSubject,
	combineLatest,
	filter,
	interval,
	map,
	take,
	tap,
} from 'rxjs';

export enum BoostTypeEnum {
	SECONDARY_SCHOOL = 1,
	ELITE_SCHOOL = 2,
	GYM = 3,
	EATERY = 4,
}
@Component({
	selector: 'ns-boosts',
	standalone: true,
	imports: [
		BalanceComponent,
		ButtonComponent,
		IconComponent,
		AsyncPipe,
		BalanceFormatPipe,
		ConfirmModalComponent,
		AsyncPipe,
		NgTemplateOutlet,
		VibrateDirective,
		WindowHeightModule,
	],
	templateUrl: './boosts.component.html',
	styleUrl: './boosts.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoostsComponent {
	readonly boostGroups$ = this.boostStoreService.boostGroups$;
	readonly BoostTypeEnum = BoostTypeEnum;
	readonly activeId$ = new BehaviorSubject<number | null>(null);

	private readonly availableRecoveryEnergyIn =
		this.userStoreService.availableRecoveryEnergyIn;
	readonly isCanReboot = computed(() => !this.availableRecoveryEnergyIn());
	readonly rebootTime$ = interval(1000).pipe(
		map(() => {
			const { minutes, seconds, hours } = DateTime.fromISO(
				this.userStoreService.availableRecoveryEnergyIn() || '',
			).diff(DateTime.now(), ['hours', 'minutes', 'seconds']);

			return {
				hours: String(Math.floor(hours)).padStart(2, '0'),
				minutes: String(Math.floor(minutes)).padStart(2, '0'),
				seconds: String(Math.floor(seconds)).padStart(2, '0'),
			};
		}),
		tap(() => {
			if (
				DateTime.now() >
				DateTime.fromISO(this.availableRecoveryEnergyIn() || '')
			) {
				this.availableRecoveryEnergyIn.set(null);
			}
		}),
	);
	// private readonly confirmRebootRef = viewChild.required('confirmRebootRef', {
	// 	read: TemplateRef,
	// });
	// private readonly confirmPaymentRef = viewChild.required(
	// 	'confirmPaymentRef',
	// 	{
	// 		read: TemplateRef,
	// 	},
	// );
	private rebootDialogRef?: DialogRef;
	private paymentDialogRef?: DialogRef;

	readonly groupNames$ = this.boostGroups$.pipe(
		filter(isNotNull),
		tap((groups) => this.activeId$.next(groups[0].id)),
		map((groups) => groups.map(({ name, id }) => ({ name, id }))),
	);
	readonly selectedGroup$ = combineLatest([
		this.activeId$,
		this.boostGroups$,
	]).pipe(
		map(([id, groups]) => ({
			...groups?.find((group) => group.id === id),
			isAvailable: false,
		})),
	);

	constructor(
		private dialog: Dialog,
		private boostStoreService: BoostStoreService,
		private boostService: BoostService,
		private userStoreService: UserStoreService,
	) {}

	confirmPayment(
		template: TemplateRef<any>,
		{ name, icon, nextLevel }: BoostType,
	): void {
		if (!nextLevel) {
			return;
		}

		const { incr } = nextLevel;
		this.paymentDialogRef = this.dialog.open(template, {
			data: {
				header: name,
				text: `+${incr} в час`,
				icon: icon,
			},
		});
	}

	buyBoost(levelId: number, incr: number): void {
		this.boostService
			.buyBoost(levelId)
			.pipe(take(1))
			.subscribe(() => {
				this.userStoreService.rewardPerHour.update((value) => {
					return value + incr;
				});
				this.paymentDialogRef?.close();
				this.boostStoreService.fetchBoostGroups();
			});
	}

	openLetMeetModal(): void {
		this.dialog.open(LetMeetModalComponent);
	}

	openConfirmEnergyModal(template: TemplateRef<any>): void {
		this.rebootDialogRef = this.dialog.open(template, {
			data: {
				header: 'Запас энергии',
				text: 'Увеличивает максимальный запас энергии',
				icon: 'assets/energy-reserve.svg',
			},
		});
	}

	openConfirmMultitapModal(template: TemplateRef<any>): void {
		this.rebootDialogRef = this.dialog.open(template, {
			data: {
				header: 'Мультитап',
				text: '+1 за каждый тап',
				icon: 'assets/multitap.svg',
			},
		});
	}

	openConfirmRebootModal(template: TemplateRef<any>): void {
		this.rebootDialogRef = this.dialog.open(template, {
			data: {
				header: 'Перезагрузка',
				text: 'Полностью восстанавливает энергию',
				icon: 'assets/reboot.svg',
			},
		});
	}

	recoveryEnergy(): void {
		this.boostService
			.recoveryEnerge()
			.pipe(take(1))
			.subscribe(({ maximumEnergy, availableRecoveryEnergyIn }) => {
				this.userStoreService.currentEnergy.set(maximumEnergy);
				this.userStoreService.availableRecoveryEnergyIn.set(
					availableRecoveryEnergyIn,
				);
				this.rebootDialogRef?.close();
			});
	}

	checkGroup(): void {
		this.boostService
			.checkBoost(this.activeId$.value!)
			.pipe(take(1))
			.subscribe((newGroup) => {
				const updatedGroups = this.boostGroups$.value?.map((group) =>
					group.id === this.activeId$.value ? newGroup : group,
				)!;
				this.boostGroups$.next(updatedGroups);
			});
	}
}
