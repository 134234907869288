import { Location } from '@angular/common';
import { Directive } from '@angular/core';

@Directive({
  selector: '[nsLocationBack]',
  standalone: true,
  host: {
    '(click)': 'this.location.back()',
  },
})
export class LocationBackDirective {
  constructor(private location: Location) {}
}
