<ns-dialog-close />
<div class="days">
  <div class="days__item passed">
    <div class="reward">
      <img src="assets/pixel.svg" alt="" />
      100
    </div>
    <h3>1</h3>
    <p>День</p>
  </div>
  <div class="days__item active">
    <div class="reward">
      <img src="assets/pixel.svg" alt="" />
      100
    </div>
    <h3>1</h3>
    <p>День</p>
  </div>
  <div class="days__item">
    <div class="reward">
      <img src="assets/pixel.svg" alt="" />
      100
    </div>
    <h3>1</h3>
    <p>День</p>
  </div>
  <div class="days__item">
    <div class="reward">
      <img src="assets/pixel.svg" alt="" />
      100
    </div>
    <h3>1</h3>
    <p>День</p>
  </div>
  <div class="days__item">
    <div class="reward">
      <img src="assets/pixel.svg" alt="" />
      100
    </div>
    <h3>1</h3>
    <p>День</p>
  </div>
  <div class="days__item">
    <div class="reward">
      <img src="assets/pixel.svg" alt="" />
      100
    </div>
    <h3>1</h3>
    <p>День</p>
  </div>
</div>
<button nsButton="inline">Возвращайся завтра</button>
