import { Dialog } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ButtonComponent } from '@components/ui/button/button.component';
import { CaptchaModalComponent } from '@components/ui/captcha-modal/captcha-modal.component';
import { FormFieldComponent } from '@components/ui/forms/form-field/form-field.component';
import { IconComponent } from '@components/ui/icon/icon.component';
import { LoaderComponent } from '@components/ui/loader/loader.component';
import { LogoComponent } from '@components/ui/logo/logo.component';
import { InputDirective } from '@directives/input.directive';
import { LocationBackDirective } from '@directives/location-back.directive';
import * as VKID from '@vkid/sdk';
import { IMaskDirective } from 'angular-imask';
import { interval, takeWhile } from 'rxjs';

@Component({
  selector: 'ns-login',
  standalone: true,
  imports: [
    LogoComponent,
    FormFieldComponent,
    InputDirective,
    ReactiveFormsModule,
    FormsModule,
    IMaskDirective,
    IconComponent,
    ButtonComponent,
    RouterLink,
    LoaderComponent,
    LocationBackDirective,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  readonly phoneControl = new FormControl('', Validators.required);
  readonly codeControl = new FormControl('', Validators.required);
  hasCode = true;
  countdown = 12;
  private readonly interval$ = interval(1000).pipe(
    takeWhile(() => !!this.countdown)
  );
  readonly vkPayload = this.route.snapshot.queryParams['payload'];

  constructor(
    private dialog: Dialog,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    this.interval$.subscribe(() => {
      this.countdown -= 1;
      this.cdr.markForCheck();
      // if (this.countdown === 0) this.loadingUid = false;
    });

    if (this.vkPayload) {
      this.authorize();
    }
  }
  getCode(): void {
    VKID.Config.set({
      redirectUrl: '',
    });
    VKID.Auth.login();
  }

  authorize(): void {}

  openCaptchaModal(): void {
    this.dialog.open(CaptchaModalComponent);
  }

  onComplete(): void {}
}
