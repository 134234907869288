import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'range',
    standalone: true
})
export class RangePipe implements PipeTransform {
	transform(to: number, from: number = 0): Array<number> {
		if (to <= 0) {
			return [];
		}

		return new Array(to - from)
			.fill(null)
			.map((_, i) => (i + from));
	}
}
