import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BalanceComponent } from '@components/balance/balance.component';
import { IconComponent } from '@components/ui/icon/icon.component';
import { VibrateDirective } from '@directives/vibrate.directive';
import { DailyRewardModalComponent } from '@pages/tasks/components/daily-reward-modal/daily-reward-modal.component';
import { ToDoTaskModalComponent } from '@pages/tasks/components/to-do-task-modal/to-do-task-modal.component';

@Component({
	selector: 'ns-tasks',
	standalone: true,
	imports: [BalanceComponent, IconComponent, VibrateDirective],
	templateUrl: './tasks.component.html',
	styleUrl: './tasks.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksComponent {
	constructor(private dialog: Dialog) {}

	openTodoTaskModal(): void {
		this.dialog.open(ToDoTaskModalComponent);
	}

	openDailyRewardModal() {
		this.dialog.open(DailyRewardModalComponent);
	}
}
