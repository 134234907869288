import { animate, style, transition, trigger } from '@angular/animations';
import { Dialog } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  ChildrenOutletContexts,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { IconComponent } from '@components/ui/icon/icon.component';
import { PlateComponent } from '@components/ui/layout/plate/plate.component';
import { LevelModalComponent } from '@components/ui/modals/level-modal/level-modal.component';
import { WelcomeBonusModalComponent } from '@components/ui/modals/welcome-bonus-modal/welcome-bonus-modal.component';
import { ProgressBarComponent } from '@components/ui/progress-bar/progress-bar.component';
import { VibrateDirective } from '@directives/vibrate.directive';
import { LevelInfoType } from '@models/user.model';
import { BoostStoreService } from '@services/boost-store.service';
import { RouteHistoryService } from '@services/route-history.service';
import { TaskStoreService } from '@services/task-store.service';
import { TelergamService } from '@services/telergam.service';
import { UserStoreService } from '@services/user-store.service';
import { UserService } from '@services/user.service';
import { combineLatest, filter, interval, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'ns-shell',
  standalone: true,
  imports: [
    RouterOutlet,
    IconComponent,
    RouterLink,
    RouterLinkActive,
    PlateComponent,
    ProgressBarComponent,
    IconComponent,
    AsyncPipe,
    VibrateDirective,
  ],
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('routeAnimations', [
      transition('* <=> *', [
        style({ opacity: 0 }),
        animate('200ms ease-in-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class ShellComponent implements OnInit, OnDestroy {
  readonly photoUrl = this.telergamService.tgData?.user?.photoUrl;

  readonly level = this.userStoreService.level;
  readonly precent = this.userStoreService.precent;
  readonly maxEnergy = this.userStoreService.maxEnergy;
  readonly currentEnergy = this.userStoreService.currentEnergy;
  readonly precentEnergy = computed(
    () => (this.currentEnergy() / this.maxEnergy()) * 100
  );
  private readonly balance = this.userStoreService.balance;
  private readonly rewardPerSecond = this.userStoreService.rewardPerSecond;
  readonly currentUrl$ = this.routeHistoryService.currentUrl$;
  readonly updateInfoTrigger$ = combineLatest([
    toObservable(this.balance).pipe(filter(Boolean)),
    toObservable(this.userStoreService.balanceForNextLevel).pipe(
      filter(Boolean)
    ),
  ]);

  constructor(
    private telergamService: TelergamService,
    private userStoreService: UserStoreService,
    private taskStoreService: TaskStoreService,
    private contexts: ChildrenOutletContexts,
    private dialog: Dialog,
    private userService: UserService,
    private routeHistoryService: RouteHistoryService,
    private boostStoreService: BoostStoreService
  ) {}

  ngOnInit(): void {
    this.userStoreService.fetchProgress();
    this.taskStoreService.fetchTasks();
    this.boostStoreService.fetchBoostGroups();
    this.userStoreService.fetchFriends();
    this.userStoreService.fetchLevelsInfo();
    this.openWelcomeBonusModal();
    this.active();
    this.telergamService.closeEvent(this.userService.active);
    this.updateUserInfo();
  }

  updateUserInfo(): void {
    this.updateInfoTrigger$
      .pipe(
        filter(
          ([balance, balanceForNextLevel]) => balance === balanceForNextLevel
        )
      )
      .subscribe(() => {
        const { reward } = this.userStoreService
          .levelsListInfo()
          .find(({ level }) => level === this.level()) as LevelInfoType;
        this.level.update((level) => level + 1);
        this.balance.update((balance) => balance + reward);
      });
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
      'animation'
    ];
  }

  openLevelModal(): void {
    this.dialog.open(LevelModalComponent);
  }

  openWelcomeBonusModal(): void {
    this.dialog.open(WelcomeBonusModalComponent);
  }

  active(): void {
    interval(30000)
      .pipe(
        startWith(null),
        switchMap(() => this.userService.active())
      )
      .subscribe();

    interval(1000).subscribe(() => {
      if (this.currentEnergy() < this.maxEnergy()) {
        this.currentEnergy.update((energy) => energy + 1);
      }
      this.balance.update((value) => value + this.rewardPerSecond());
    });
  }

  ngOnDestroy(): void {
    this.dialog.closeAll();
  }
}
