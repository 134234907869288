import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '@components/ui/button/button.component';
import { DialogCloseComponent } from '@components/ui/dialog-close/dialog-close.component';

@Component({
  selector: 'ns-let-meet-modal',
  standalone: true,
  imports: [DialogCloseComponent, ButtonComponent, RouterLink],
  templateUrl: './let-meet-modal.component.html',
  styleUrl: './let-meet-modal.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LetMeetModalComponent {}
