<header>
  <ns-logo />
  <button nsLocationBack>
    <ns-icon icon="close" />
  </button>
</header>
<div class="wrapper">
  <h1>Привет 👋🏻</h1>
  <p class="welcome-text">Авторизуйся, чтобы начать заниматься</p>
  <div class="phone-container">
    <ns-form-field>
      <input
        nsInput
        [formControl]="phoneControl"
        [imask]="{ mask: '{+7} (000) 000-00-00' }"
        [unmask]="true"
        minlength="12"
        placeholder="+7"
      />
    </ns-form-field>
    @if(hasCode) {
    <button nsButton="gray">
      <ns-loader />
      {{ countdown }} сек
    </button>
    } @else {
    <button nsButton="gray">Новый код</button>
    }
  </div>
  @if(phoneControl.value && !hasCode) {
  <button
    nsButton="gray"
    [disabled]="phoneControl.invalid"
    (click)="openCaptchaModal()"
  >
    Получить код
  </button>
  } @if(hasCode) {
  <ns-form-field>
    <input
      nsInput
      [formControl]="codeControl"
      [imask]="{ mask: '0000', lazy: false }"
      [unmask]="true"
      (complete)="onComplete()"
    />
  </ns-form-field>
  <p class="code-text">
    введите последние 4&nbsp;цифры номера, с&nbsp;которого поступит звонок
  </p>
  }

  <div class="divider">&mdash;&nbsp;или&nbsp;&mdash;</div>
  <button (click)="getCode()" class="vk-btn">
    Войти через
    <ns-icon icon="vk-logo-inverted" />
  </button>
  <div class="agreement">
    Авторизуясь, ты принимаешь<br />
    <a
      class="agreement__link"
      target="_blank"
      href="https://storage.yandexcloud.net/newschool-media-prod/legal_documents/d7a86c3c0f284439a1cd495fb0ed34c6.docx?AWSAccessKeyId=E-AkrD-j-LFOVUbniAQg&Signature=ilsR2JecGzwdKSGQ%2FTnPxvT3pFE%3D&Expires=1975503603"
      >пользовательское соглашение</a
    >
    <br />
    и
    <a class="agreement__link" target="_blank" routerLink="/privacy-police"
      >политику конфиденциальности</a
    >
  </div>
</div>
