import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '@components/ui/button/button.component';
import { IconComponent } from '@components/ui/icon/icon.component';
import { BalanceFormatPipe } from '@pipes/balance-format.pipe';
import { TelergamService } from '@services/telergam.service';
import { UserStoreService } from '@services/user-store.service';
import { UserService } from '@services/user.service';

@Component({
	selector: 'ns-friends',
	standalone: true,
	imports: [ButtonComponent, IconComponent, BalanceFormatPipe],
	templateUrl: './friends.component.html',
	styleUrl: './friends.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FriendsComponent {
	readonly friends = this.userStoreService.friends;

	constructor(
		private userStoreService: UserStoreService,
		private userService: UserService,
		private telergamService: TelergamService,
	) {}

	inviteFriend(): void {
		this.userService.getReferal().subscribe(({ id }) => {
			this.telergamService.webApp.shareMessage(id);
		});
	}
}
