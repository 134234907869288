import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RangePipe } from '@pipes/range.pipe';

@Component({
  selector: 'ns-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.large]': "size === 'large'",
    '[class.medium]': "size === 'medium'",
    '[class.small]': "size === 'small'",
  },
  standalone: true,
  imports: [RangePipe],
})
export class LoaderComponent {
  @Input() size: 'medium' | 'large' | 'small' = 'small';
}
