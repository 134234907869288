<ns-balance />
<h2>Розыгрыш</h2>
<div class="draw">
	<img src="assets/draw.png" alt="" />
	<button (click)="openLetMeetModal()" nsVibrate>Участвовать</button>
</div>
<h2>Подписки</h2>
<button (click)="openPaymentPrize()" nsVibrate class="container">
	<div>
		<img src="" alt="" />
		<p>ГДЗ Премиум</p>
		<div class="tag">
			<img src="assets/pixel.png" alt="" />
			3M
		</div>
	</div>
</button>
<h2>Выбери Пикси</h2>
<h2>Выйди из комнаты</h2>
