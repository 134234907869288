import { computed, Injectable, signal } from '@angular/core';
import { FriendType } from '@models/friends.model';
import { LevelInfoType } from '@models/user.model';
import { UserService } from '@services/user.service';
import { DateTime } from 'luxon';
import { take } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class UserStoreService {
	readonly balance = signal(0);
	readonly clicks = signal(0);
	readonly maxEnergy = signal(0);
	readonly currentEnergy = signal(0);
	readonly precent = computed(() =>
		Math.round((this.balance() / this.balanceForNextLevel()) * 100),
	);

	readonly rewardPerClick = signal(0);
	readonly rewardPerHour = signal(0);
	readonly rewardPerSecond = computed(() => this.rewardPerHour() / 60 / 60);
	readonly availableRecoveryEnergyIn = signal<string | null>(null);

	readonly level = signal(0);
	readonly balanceForNextLevel = signal(0);
	readonly friends = signal<FriendType[]>([]);

	readonly levelsListInfo = signal<LevelInfoType[]>([]);

	readonly passiveIncome = signal(0);

	constructor(private userService: UserService) {}

	fetchProgress(): void {
		this.userService
			.getProgress()
			.pipe(take(1))
			.subscribe(
				({
					balance,
					clicks,
					energy,
					rewardPerClick,
					rewardPerHour,
					maximumEnergy,
					availableRecoveryEnergyIn,
					level,
					balanceForNextLevel,
					userActivity,
				}) => {
					this.balance.set(balance);
					this.clicks.set(clicks);
					this.maxEnergy.set(maximumEnergy);
					this.currentEnergy.set(energy);
					this.rewardPerClick.set(rewardPerClick);
					this.rewardPerHour.set(rewardPerHour);
					this.availableRecoveryEnergyIn.set(
						availableRecoveryEnergyIn,
					);

					this.level.set(level);
					this.balanceForNextLevel.set(balanceForNextLevel);
					this.setPassiveIncome(userActivity);
				},
			);
	}

	setPassiveIncome(userActivity: string): void {
		const { seconds } = DateTime.now().diff(
			DateTime.fromISO(userActivity),
			['seconds'],
		);

		this.passiveIncome.set(seconds * this.rewardPerSecond());
		console.log(this.passiveIncome());
	}

	fetchFriends(): void {
		this.userService
			.getFriends()
			.pipe(take(1))
			.subscribe((friends) => this.friends.set(friends));
	}

	fetchLevelsInfo() {
		this.userService
			.getLevelsInfo()
			.pipe(take(1))
			.subscribe((info) => this.levelsListInfo.set(info));
	}
}
