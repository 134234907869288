<div class="video-container">
  <video
    [autoplay]="true"
    [muted]="true"
    [loop]="true"
    poster="assets/location.png"
  >
    <source src="assets/video/location.mp4" />
  </video>
</div>
@if((currentUrl$ | async)) {
<div class="blur"></div>
}

<ns-plate (click)="$event.stopPropagation()" class="header">
  <img [src]="photoUrl" alt="" />
  <div class="bar-wrapper level">
    <div>
      <div class="bar-wrapper__text">
        <div>
          LVL {{ level() }}
          <button (click)="openLevelModal()">
            <ns-icon icon="arrow-full" size="16" />
          </button>
        </div>

        <p>{{ level() }}/10</p>
      </div>
      <ns-progress-bar
        background="rgba(140, 186, 255, 0.4)"
        color="rgba(185, 225, 255, 1)"
        [precent]="precent()"
      />
    </div>
  </div>
  <div class="bar-wrapper score">
    <ns-icon icon="lightning" width="12" height="21"></ns-icon>
    <div>
      <div class="bar-wrapper__text">
        <p>{{ currentEnergy() }}/{{ maxEnergy() }}</p>
      </div>
      <ns-progress-bar
        background="rgba(151, 85, 209, 0.4)"
        color="#B163F5"
        [precent]="precentEnergy()"
      />
    </div>
  </div>
</ns-plate>
<div [@routeAnimations]="getRouteAnimationData()">
  <router-outlet></router-outlet>
</div>

<ns-plate class="nav-plate">
  <a
    routerLink="/"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
    nsVibrate
  >
    <img src="assets/navigation/main.svg" alt="" />
    Главная
  </a>
  <a routerLink="boosts" routerLinkActive="active" nsVibrate>
    <img src="assets/navigation/boosts.svg" alt="" />
    Бусты
  </a>
  <a routerLink="tasks" routerLinkActive="active" nsVibrate>
    <img src="assets/navigation/tasks.svg" alt="" />
    Задания
  </a>
  <a routerLink="friends" routerLinkActive="active" nsVibrate>
    <img src="assets/navigation/friends.svg" alt="" />
    Друзья
  </a>
  <a routerLink="prizes" routerLinkActive="active" nsVibrate>
    <img src="assets/navigation/prizes.svg" alt="" />
    Призы
  </a>
</ns-plate>
