import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TicketType } from '@models/jackpot.model';
import { camelCaseKeys } from '@utils/camel-case';
import { map, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class JackpotService {
	constructor(private http: HttpClient) {}

	getTickets(): Observable<TicketType> {
		return this.http.get<TicketType>('jackpot').pipe(map(camelCaseKeys));
	}

	buyTicket(id: number): Observable<void> {
		return this.http.post<void>(`jackpot/${id}/join`, {});
	}
}
