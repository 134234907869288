import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '@components/ui/button/button.component';
import { DialogCloseComponent } from '@components/ui/dialog-close/dialog-close.component';
@Component({
  selector: 'ns-payment-prizes-modal',
  standalone: true,
  imports: [DialogCloseComponent, ButtonComponent],
  templateUrl: './payment-prizes-modal.component.html',
  styleUrl: './payment-prizes-modal.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentPrizesModalComponent {}
