<div class="header">
  <h2>Введи код с&nbsp;картинки</h2>
  <p>Мы&nbsp;должны убедиться, что ты&nbsp;не&nbsp;<br />робот</p>
  <button nsDialogClose></button>
</div>
<div class="captcha">
  @if(!isLoading) {
  <img [src]="'data:image/png;base64,' + imageSrc" alt="CAPTCHA" />
  }

  <!-- <ns-loader *ngIf="isLoading" /> -->
  <button nsButton (click)="getCaptcha()">
    <ns-icon icon="repeat" />
  </button>
</div>
<div class="footer">
  <ns-form-field>
    <input
      nsInput
      [formControl]="answerUser"
      (keyup.enter)="sendCheckCaptcha()"
      placeholder="Введи код"
    />

    @if(answerUser.invalid) {
    <ns-error>Неверно, попробуй еще раз</ns-error>
    }
  </ns-form-field>
  <button
    nsButton="blue"
    (click)="sendCheckCaptcha()"
    [disabled]="!answerUser.value"
  >
    Отправить
  </button>
</div>
