<ns-balance />
<div class="tasks">
	<h2>Ежедневный бонус</h2>
	<button
		(click)="openDailyRewardModal()"
		nsVibrate
		class="tasks__item completed"
	>
		<img src="" alt="" class="tasks__item-img" />
		<div>
			<p class="tasks__item-condition">Ежедневная награда</p>
			<p>Возвращайся завтра</p>
		</div>

		<ns-icon icon="check-tick"></ns-icon>
	</button>
</div>
<div class="tasks">
	<h2>Постоянные</h2>
	<button (click)="openTodoTaskModal()" nsVibrate class="tasks__item">
		<img src="" alt="" class="tasks__item-img" />
		<div>
			<p class="tasks__item-condition">За каждый просмотренный урок</p>
			<div class="tasks__item-reward">
				<img src="assets/pixel.svg" alt="" />
				<p>+100</p>
			</div>
		</div>
		<ns-icon icon="arrow-short"></ns-icon>
	</button>
</div>
