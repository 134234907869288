<!-- @if(tasks().length) {
<div (click)="$event.stopPropagation()" class="tasks">
  @for(task of tasks(); track task.id) {
  <ns-plate class="tasks__item">
    <p>{{ task.value }}</p>
    <div>
      <img src="assets/pixel.svg" alt="" />
      x{{ task.reward }}
    </div>
  </ns-plate>
  }
</div>
} @else { @if(isShowPlug) {
<ns-plate (click)="$event.stopPropagation()" class="tasks-plug">
  <p>На сегодня задания закончились</p>
  <button (click)="isShowPlug = !isShowPlug">
    <ns-icon icon="close" size="16"></ns-icon>
  </button>
</ns-plate>
} } -->

<div
	(pointerdown)="$event.stopPropagation()"
	(click)="$event.stopPropagation()"
	class="tasks"
	[class.is-not-auth]="true"
>
	<!-- @for(task of tasks; track $index) {
  <ns-plate class="tasks__item">
    <p>{{ task.value }}</p>
    <div>
      <img src="assets/pixel.svg" alt="" />
      x{{ task.reward }}
    </div>
  </ns-plate>
  } -->
	<button (click)="openLetMeetModal()" nsVibrate>
		<ns-plate class="tasks__item">
			<p>Войди в аккаунт Новой Школы</p>
			<div>
				<img src="assets/pixel.svg" alt="" />
				x100 000
			</div>
		</ns-plate>
	</button>
</div>

<ns-balance />
<div>
	<canvas #live2dCanvas></canvas>
</div>
