<button nsDialogClose></button>
<img src="" alt="" class="boost-img" />
<h3>Сложная домашка</h3>
<div class="reward">
  <img src="assets/pixel.svg" alt="" />
  <p>+200</p>
</div>
<div class="btn-group">
  <button nsButton>Сделать</button>
  <button nsButton="inline">Проверить</button>
</div>
