import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent } from '@components/ui/button/button.component';
import { DialogCloseComponent } from '@components/ui/dialog-close/dialog-close.component';

@Component({
  selector: 'ns-daily-reward-modal',
  standalone: true,
  imports: [DialogCloseComponent, ButtonComponent],
  templateUrl: './daily-reward-modal.component.html',
  styleUrl: './daily-reward-modal.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailyRewardModalComponent {}
