import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Observable, startWith, Subject, takeUntil } from 'rxjs';

export abstract class FormFieldControl {
  readonly disabled: boolean = false;
  readonly focused: boolean = false;
  readonly readonly: boolean = false;
  readonly autofilled: boolean = false;
  readonly controlType: string = '';
  readonly errorState: boolean = false;
  readonly stateChanges?: Observable<void>;
  readonly ngControl?: NgControl | null;

  abstract get empty(): boolean;

  abstract get shouldLabelFloat(): boolean;

  abstract onContainerClick(): void;
}

@Component({
  selector: 'ns-form-field',
  standalone: true,
  imports: [],
  templateUrl: './form-field.component.html',
  styleUrl: './form-field.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.has-error]': 'control.errorState',
  },
})
export class FormFieldComponent {
  @ContentChild(FormFieldControl) control?: FormFieldControl;
  @Input() size: 'small' | 'medium' | 'large' | 'extra-large' = 'medium';
  private destroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {}

  ngAfterContentInit() {
    this.validateControlChild();

    if (!this.control) {
      return;
    }

    if (this.control.controlType) {
      this.elementRef.nativeElement.classList.add(
        `form-field-type-${this.control.controlType}`
      );
    }

    this.control.stateChanges?.pipe(startWith(null!)).subscribe(() => {
      this.cdr.markForCheck();
    });

    // Run change detection if the value changes.
    if (this.control.ngControl && this.control.ngControl.valueChanges) {
      this.control.ngControl.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.cdr.markForCheck());
    }
  }

  protected validateControlChild() {
    if (!this.control) {
      throw new Error('form-field must contain a FormFieldControl.');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
