import { Injectable } from '@angular/core';
import { camelCaseKeys } from '@utils/camel-case';

declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class TelergamService {
  readonly webApp = window.Telegram.WebApp;
  readonly tgData = camelCaseKeys(window.Telegram.WebApp.initDataUnsafe);

  vibrate(): void {
    this.webApp.HapticFeedback.impactOccurred('medium');
  }

  closeEvent(callback: () => void) {
    window.Telegram.WebApp.onEvent('webAppClose', callback);
  }
}
