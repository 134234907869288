import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ns-progress-bar',
  standalone: true,
  imports: [],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.background]': 'this.background',
  },
})
export class ProgressBarComponent {
  @Input() background = '';
  @Input() color = '';
  @Input() precent?: number;
}
