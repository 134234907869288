import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DialogCloseComponent } from '@components/ui/dialog-close/dialog-close.component';

interface IDialogData {
  header: string;
  text: string;
  icon: string;
}
@Component({
  selector: 'ns-confirm-modal',
  standalone: true,
  imports: [DialogCloseComponent],
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent {
  constructor(
    @Inject(DIALOG_DATA)
    public dialogData: IDialogData
  ) {}
}
