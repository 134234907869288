import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FriendType } from '@models/friends.model';
import { LevelInfoType, ProgressType } from '@models/user.model';
import { camelCaseKeys } from '@utils/camel-case';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getProgress(): Observable<ProgressType> {
    return this.http
      .get<ProgressType>('user/progress')
      .pipe(map(camelCaseKeys));
  }

  click(): Observable<void> {
    return this.http.post<void>('user/click', {});
  }

  active(): Observable<void> {
    return this.http.post<void>('user/active', {});
  }

  getFriends(): Observable<FriendType[]> {
    return this.http.get<FriendType[]>('user/friends').pipe(map(camelCaseKeys));
  }

  getReferal(): Observable<{ id: number }> {
    return this.http.get<{ id: number }>('user/referral');
  }

  getLevelsInfo(): Observable<LevelInfoType[]> {
    return this.http
      .get<LevelInfoType[]>('user/levels')
      .pipe(map(camelCaseKeys));
  }
}
