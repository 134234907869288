import { HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '@components/ui/button/button.component';
import { DialogCloseComponent } from '@components/ui/dialog-close/dialog-close.component';
import { ErrorComponent } from '@components/ui/forms/error/error.component';
import { FormFieldComponent } from '@components/ui/forms/form-field/form-field.component';
import { IconComponent } from '@components/ui/icon/icon.component';
import { InputDirective } from '@directives/input.directive';
@Component({
  selector: 'ns-captcha-modal',
  standalone: true,
  imports: [
    ButtonComponent,
    FormFieldComponent,
    InputDirective,
    FormsModule,
    ReactiveFormsModule,
    IconComponent,
    ErrorComponent,
    DialogCloseComponent,
  ],
  templateUrl: './captcha-modal.component.html',
  styleUrl: './captcha-modal.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaptchaModalComponent {
  @Output() resultInputCaptcha = new EventEmitter<{
    code: string;
    contentDisposition: string;
  }>();
  answerUser = new FormControl<string>('', { nonNullable: true });
  captchaResponse?: HttpResponse<{ image: string }>;
  imageSrc = '';
  isLoading = true;

  constructor() {}

  getCaptcha(): void {}

  sendCheckCaptcha(): void {
    this.answerUser.setValue('');
    this.answerUser.setErrors({ incorrect: true });
    console.log(this.answerUser.invalid);
  }
}
