import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TaskType } from '@models/task.model';
import { snakeCaseKeys } from '@utils/camel-case';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  constructor(private http: HttpClient) {}

  getTask(): Observable<TaskType[]> {
    return this.http.get<TaskType[]>('task');
  }

  taskCompleted() {
    return this.http.get('task/completed');
  }

  taskCheck(taskId: number) {
    return this.http.post('task/check', { params: snakeCaseKeys({ taskId }) });
  }

  // healtchecker() {
  //   return this.http.get('healthchecker');
  // }
}
