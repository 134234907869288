<button nsDialogClose></button>
<h3>Уровень {{ level() }}</h3>
<p class="progress-balance">
  {{ balance() | balanceFormat : 1 }} /
  {{ balanceForNextLevel() | balanceFormat }}
</p>
<ns-progress-bar
  background="rgba(140, 186, 255, 0.4)"
  color="rgba(185, 225, 255, 1)"
  [precent]="precent()"
/>
<p class="description">
  Обновляй уровень и получай<br />
  больше пикселей
</p>
<div class="levels">
  <div class="levels__header">
    <p>Уровень</p>
    <p>Сумма</p>
  </div>
  @for(info of levelsInfo() | sort: 'level'; track $index) {
  <div>
    <p>Уровень {{ info.level }}</p>
    <div class="balance">
      <img src="assets/pixel.svg" alt="" />
      {{ info.balanceForLevel | balanceFormat }}+
    </div>
  </div>
  }
</div>
